<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col sm="12" class="package-details-name">
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('orders_page.order_details_title') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.order_number') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">#{{ id }}</p>
      </b-col>
    </b-row>
    <b-row
      v-if="
        status == 0 || status == 1 || status == 2 || status == 3 || status == 4
      "
      class="package-details-table__body"
    >
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.order_status') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <div class="d-flex">
          <b-badge
            pill
            :variant="'light-' + statusList[1][status]"
            :class="'border-' + statusList[1][status]"
            class="text-reg-12 py-0 px-3"
            >{{ $t('common.' + statusList[0][status]) }}</b-badge
          >
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="$route.params.role == 'employee'"
      class="package-details-table__body"
    >
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.end_date') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value d-flex align-items-center">
        <b-badge
          pill
          :variant="'light-warning'"
          :class="'border-warning'"
          class="text-reg-12 py-0 px-3"
          >{{ formatedDate(date) }}</b-badge
        >
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">
          {{ $t('form_wizard_order.management_title_title') }}:
        </p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">
          {{ title }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('form_wizard_order.job_title_required') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">
          {{ employeeJobTitle }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.job_req_no') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">
          {{ requiredJobsNo }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.field') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">
          {{ industryPreference }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.sector') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value d-flex">
        <b-badge
          v-for="(item, index) in rolePreference"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-lg-3 rounded-8"
        >
          {{ item.name }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.required_services') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value d-flex">
        <b-badge
          v-for="(item, index) in services"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-lg-3 rounded-8"
        >
          {{ item.name }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.job_described') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <p class="mb-0">
          {{ description }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.attachments') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0">
          <label
            class="open-file-icon"
            v-if="file && file.file_name"
            @click="openFile(file)"
          >
            <span v-if="file.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{ file.name ? file.name.split('_')[0] : file.name }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ file.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <hr v-if="status == 3 || status == 2" />
    <b-row v-if="status == 3 || status == 2" class="w-100 m-0">
      <b-col sm="12" class="package-details-name py-5 d-flex p-0-30">
        <wameed-btn
          classes="text-med-14 text-white rounded-10 mx-md-5 mx-0 min-width-96"
          :title="$t('common.sharing')"
          type="button"
          variant="main"
          @submitAction="showSharingOrder = true"
        />
        <wameed-btn
          classes="text-med-14 text-white rounded-10 mx-md-5 mx-2 min-width-96"
          :title="$t('common.refusal')"
          type="button"
          variant="danger"
          @submitAction="showRejectedOrder = true"
        />
        <wameed-btn
          classes="ml-md-auto mr-md-5 mr-0 text-med-14 text-font-secondary rounded-10 min-width-96"
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          onclick="history.back()"
        />
      </b-col>
    </b-row>

    <hr v-if="status == 1 && offersSent == false && hasOffers == true" />
    <b-row
      v-if="status == 1 && offersSent == false && hasOffers == true"
      class="w-100 m-0"
    >
      <b-col sm="12" class="package-details-name py-5 d-flex p-0-30">
        <wameed-btn
          classes="text-med-14 text-white rounded-10 mr-5"
          :title="$t('common.select_offers')"
          type="button"
          variant="main"
          @submitAction="selectOffersRoute"
        />
        <wameed-btn
          classes="ml-auto mr-5 text-med-14 text-font-secondary rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          onclick="history.back()"
        />
      </b-col>
    </b-row>
    <warning-modal
      variant="main"
      variant2="warning"
      icon-name="warning-icon"
      :visible="showSharingOrder"
      :title="$t('common.sharing_project_title')"
      :sub-title="$t('common.sharing_project_subtitle')"
      :btn-title="$t('common.sharing')"
      @close="showSharingOrder = false"
      @submitAction="sharingOrder()"
    />
    <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
    >
      <b-modal
        id="wameed-modal"
        v-model="showRejectedOrder"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        scrollable
        hide-header
      >
        <div class="align-items-center w-100">
          <div
            class="
              d-flex
              flex-column
              justify-content-between
              align-items-center
              w-75
              mx-auto
              mt-5
            "
          >
            <b-avatar :variant="'light-danger'" rounded size="76" class="mb-5">
              <rejected-icon />
            </b-avatar>

            <h4 class="text-med-20 py-0 text-dark">
              {{ $t('common.rejected_project_title') }}
            </h4>
            <h4 class="text-reg-16 py-0 text-center text-font-secondary">
              {{ $t('common.rejected_project_subtitle') }}
            </h4>
          </div>
        </div>
        <wameed-form refs="addOfferForm">
          <template slot="form-content" slot-scope="{ invalid }">
            <div class="py-1 px-1">
              <text-area
                id="new-password"
                v-model="rejectedDescription"
                :label="$t('common.reason_refuse')"
                field-classes="mb-0"
                input-classes="text-med-14 text-font-main"
                name="rejectedDescription"
                rules="required"
                :placeholder="$t('common.reason_refuse_placeholder')"
                rows="5"
              />
            </div>
          </template>
        </wameed-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center w-100">
            <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('common.refusal')"
              :disabled="invalid"
              type="submit"
              variant="danger"
              @submitAction="rejectedOrder"
            />
            <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="showRejectedOrder = false"
            />
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import WarningModal from '@/components/WarningModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '../../TextArea.vue';

export default {
  components: {
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    employeeJobTitle: {
      type: String,
      default: null,
    },
    requiredJobsNo: {
      type: Number,
      default: null,
    },
    industryPreference: {
      type: String,
      default: null,
    },
    rolePreference: {
      type: Array,
      default: null,
    },
    file: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    services: {
      type: Array,
      default: null,
    },
    offersSent: {
      type: Boolean,
      default: null,
    },
    hasOffers: {
      type: Boolean,
      default: null,
    },
    badge: {
      type: Object,
      default() {
        return {
          name: 'مقبول',
          color: 'success',
        };
      },
    },
  },
  data() {
    return {
      showSharingOrder: false,
      showRejectedOrder: false,
      rejectedDescription: '',
      statusList: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      changeOrdersStatus: 'changeOrdersStatus',
    }),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    cancelOrder() {
      this.$router.push({
        name: 'orders-list',
        params: { lang: this.$i18n.locale },
      });
    },
    selectOffersRoute() {
      this.$router.push({
        name: 'offers-project',
        params: {
          lang: this.$i18n.locale,
          id: this.$router.currentRoute.params.id,
        },
      });
    },
    sharingOrder() {
      this.changeOrdersStatus({
        id: this.$router.currentRoute.params.id,
        status: 1,
      }).then(() => {
        this.showSharingOrder = false;
      });
    },
    rejectedOrder() {
      this.changeOrdersStatus({
        id: this.$router.currentRoute.params.id,
        status: 0,
        description: this.rejectedDescription,
      }).then(() => {
        this.showRejectedOrder = false;
      });
    },
    openFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
  },
};
</script>
