<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col sm="12" class="package-details-name">
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('orders_page.offer_details_title') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.offer_no') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">#{{ id }}</p>
      </b-col>
    </b-row>
    <b-row v-if="employee" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('offers_page.presenter_name') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ employee.name }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.journey_start_date') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ formatedDate(date) }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.offer_price_2') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">{{ price }} ر.س</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.offer_described') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ description }}
        </p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.attachments') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0" dir="rtl">
          <label
            class="open-file-icon"
            v-if="file && file.file_name"
            @click="openFile(file)"
          >
            <span v-if="file.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{ file.name ? file.name.split('_')[0] : file.name }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ file.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="status == 2" class="package-details-table__body">
      <wameed-btn
        v-if="status == 2"
        :classes="'rounded-12 text-bold-14 text-white ml-3'"
        :title="$t('common.card_selection')"
        type="button"
        variant="main"
        @submitAction="showSendOffers = true"
      />
      <wameed-btn
        v-if="status == 2"
        :classes="'rounded-12 text-bold-14 text-white ml-3'"
        :title="$t('common.refusal')"
        type="button"
        variant="danger"
        @submitAction="showRefusalOffers = true"
      />
      <wameed-btn
        v-if="status == 2"
        classes="ml-auto mr-3 text-med-14 text-font-secondary rounded-10 "
        :title="$t('common.cancel')"
        type="button"
        variant="disable"
        onclick="history.back()"
      />
    </b-row>
    <warning-modal
      variant="main"
      variant2="warning"
      icon-name="warning-icon"
      :visible="showSendOffers"
      :title="$t('common.send_offer_title')"
      :sub-title="$t('common.send_offer_subtitle')"
      :btn-title="$t('common.send')"
      @close="showSendOffers = false"
      @submitAction="sendOffers()"
    />

    <warning-modal
      variant="danger"
      variant2="danger"
      icon-name="stopping-icon"
      :visible="showRefusalOffers"
      :title="$t('common.rejected_offer_title')"
      :sub-title="$t('common.rejected_offer_subtitle')"
      :btn-title="$t('common.refusal')"
      @close="showRefusalOffers = false"
      @submitAction="refusalOffer()"
    />
  </div>
</template>

<script>
import WameedBtn from '@/components/WameedBtn.vue';
import WarningModal from '@/components/WarningModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    WameedBtn,
    WarningModal,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    price: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    file: {
      type: Object,
      default: null,
    },
    employee: {
      type: Object,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    badge: {
      type: Object,
      default() {
        return {
          name: 'مقبول',
          color: 'success',
        };
      },
    },
  },
  data() {
    return {
      showSendOffers: false,
      showRefusalOffers: false,
      listStatus: [
        {
          0: 'rejected',
          2: 'under_review',
          3: 'agreed',
        },
        {
          0: 'danger',
          2: 'font-secondary',
          3: 'success',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      sendOffersSelection: 'sendOffersSelection',
      changeOffersStatus: 'changeOffersStatus',
    }),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    openFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    cancelOffer() {
      this.$router.push({
        name: 'offers',
        params: { lang: this.$i18n.locale },
      });
    },
    sendOffers() {
      this.sendOffersSelection({
        offers: [this.$router.currentRoute.params.id],
      }).then(() => {
        this.showSendOffers = false;
        this.$router.push({
          name: 'offers',
          params: {
            lang: this.$i18n.locale,
            id: this.$router.currentRoute.params.id,
          },
        });
      });
    },
    refusalOffer() {
      this.changeOffersStatus({
        id: this.$router.currentRoute.params.id,
        status: 0,
      }).then(() => {
        this.showRefusalOffers = false;
        this.$router.push({
          name: 'offers',
          params: {
            lang: this.$i18n.locale,
            id: this.$router.currentRoute.params.id,
          },
        });
      });
    },
  },
};
</script>
